$theme-colors: (
  "primary": rgb(219, 183, 0),
  "dark": #000000,
);

@import "node_modules/bootstrap/scss/bootstrap";

/* -------- FRAMEWORK --------- */

h1 {
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
}
h2 {
  font-size: 1.4em;
  text-align: center;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase;
  font-weight: bold;
}
h3 {
  font-size: 1.2em;
  text-align: center;
  margin-top: 8px;
}
h4 {
  font-size: 1.1em !important;
  min-height: 40px;
}

p {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.2rem;
}

a {
  text-decoration: underline;
}

a:link {
  color: rgb(68, 55, 0);
}

a:visited {
  color: rgb(68, 55, 0);
}

a:hover {
  color: rgb(68, 55, 0);
}

a:active {
  color: rgb(68, 55, 0);
}

/* -------- TOP BRANDING --------- */

/*.top-branding { 
  background: rgba(255,255,255,0.8);
  box-shadow: 0px 2px 2px #777 !important;
} */

.brand-header {
  color: #fff;
  background-color: rgba(55, 55, 55, 1);
  //background-color: rgba(255,255,255,0.8);
  box-shadow: 0px 2px 2px #444;
  border-bottom: 1px solid black;
  //position: absolute;
  //top: 58px;
  padding: 20px;
}

.brand-header a:link {
  color: #d7b000;
}

.brand-header .main {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: bold;
}

.brand-header .title {
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.brand-header .subtitle {
  font-size: 1rem;
  line-height: 1.4rem;
}
.brand-header .region {
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.brand-header .callout {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
  color: rgb(255, 0, 0);
  text-align: right;
}
.brand-header .preeminent {
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.brand-header .preeminent .image {
  padding-bottom: 6px;
}

@media (min-width: 992px) {
  .brand-header {
    display: flex;
    flex-direction: row;
  }
  .brand-header .main {
    text-align: left;
  }
  .brand-header .preeminent {
    width: 340px;
  }
  .brand-header .logo,
  .brand-header .main,
  .brand-header .callout,
  .brand-header .preeminent {
    padding-bottom: 0px;
  }
}

@media (max-width: 992px) {
  .brand-header {
    display: flex;
    flex-direction: column;
  }
  .brand-header .main {
    text-align: center;
  }
  .brand-header .preeminent {
    width: 100%;
  }
  .brand-header .logo,
  .brand-header .main,
  .brand-header .callout,
  .brand-header .preeminent {
    padding-bottom: 20px;
  }
}

/* -------- MAIN CONTAINER --------- */
.main-container {
  position: relative;
  top: 1px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 2px;
}

.section {
  background-color: rgba(255, 255, 255, 1);
  padding: 24px;
}

.section .subtitle {
  text-align: center;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: bold;
  padding-bottom: 8px;
}

.frame {
  margin: 40px 0px 0px 0px;
  padding: 24px;
  background-color: white;
  background-image: linear-gradient(rgb(232, 232, 232), rgb(255, 255, 255));
  background-size: 100% 240px;
  background-repeat: no-repeat;
}

/* -------- MAIN BODY --------- */

.main-body {
  display: flex;
  align-items: top;
  margin-top: 0px;
  margin-bottom: 0px;
}
.main-body .content {
  padding-right: 20px;
}
.main-body h1 {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: left;
}

@media (min-width: 992px) {
  .main-body {
    flex-direction: row;
  }
  .main-body .content {
    padding-right: 20px;
  }
}

@media (max-width: 992px) {
  .main-body {
    flex-direction: column;
  }
  .main-body .content {
    padding-right: 0px;
  }
}

/* --------  --------- */

@media (min-width: 992px) {
  .d-flex-c {
    display: flex;
    padding-bottom: 2px;
  }
}

@media (max-width: 992px) {
  .d-flex-c {
    display: normal;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 1rem;
}

.bck01 {
  /* background-color: #ebe5dc90; */
  min-width: 260px;
  /*margin-left: 40px;*/
}

.header {
  margin: 0px;
  padding: 0px;
  /*background-color: #ebe5dc90;*/
}

.navbar {
  background: #222 !important;
  box-shadow: 0px 2px 2px #444;
  border-bottom: 1px solid black;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 10;
}

.dropdown-menu {
  background: #222;

  .dropdown-item {
    background: #222;
    color: #d7b000;
  }

  .dropdown-item:hover {
    background: #000;
    color: #d7b000;
  }
}

/* -------- WEBSITE --------- */

.hero-image {
  background-image: url("photographer.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

ul,
ol {
  list-style: none;
}

.buttons2 {
  padding: 0px !important;
  margin: 0px !important;
}

.buttons2 li a:link,
a:visited {
  background: transparent;
  font-weight: bold;
  color: black;
  border: 1px solid rgba(52, 0, 0, 0.169);
  margin: 2px 0px 2px 0px;
  padding: 2px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: 14px;
}

.form-control {
  width: 100%;
  padding: 15px 30px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.7;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
}

.section_contact .hideme {
  display: none;
  visibility: hidden;
}

.award-img {
  height: 90px !important;
  width: auto;
}

.section_footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #252525;
  color: rgba(255, 255, 255, 1);
}

.section_footer a:link {
  color: rgb(215, 176, 0);
}

.section_footer a:visited {
  color: rgb(215, 176, 0);
}

.section_footer a:hover {
  color: rgb(215, 176, 0);
}

.section_footer a:active {
  color: rgb(215, 176, 0);
}

.featured-img {
  height: 70px;
  width: auto;
  padding: 10px;
}

.elr p {
  text-indent: 50px;
}

.foot-link {
  padding: 2px;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #000;
  border-style: none !important;
  border-width: 0px !important;
  padding: 4px !important;
}

/* -------- SIDEBAR WEBINARS --------- */

//.sidebar-webinars {
//display: flex;
//flex-direction: column;

//}
.sidebar-webinars .element {
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-bottom: 4px;
}
.sidebar-webinars .media {
  width: 168px;
  margin-right: 6px;
}
.sidebar-webinars h4 {
  font-size: 14px !important;
  margin: 0px;
}
.sidebar-webinars p {
  font-size: 14px !important;
  line-height: 16px;
}

/* ---------------------------------------------- */

.elreview {
  //max-width: 860px;
  align-items: center;
}

.elreview h1 {
  text-align: center;
}
.elreview h2 {
  font-size: 1.2rem;
}
.elreviewbody h1 {
  font-size: 1.3rem;
  text-align: center;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 0px;
  margin: 20px 0px 30px 0px;
}
.elreviewbody h2 {
  font-size: 1.1rem;
}
.elreview h3 {
  margin: 0px;
  padding: 28px 10px 10px 10px;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
}

.elreview .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: streach;
  justify-content: center;
}
.elreview .element {
  position: relative;
  margin: 10px 10px 30px 10px;
  width: 340px;
  /*/min-height: 100px; */
  border: 1px solid rgb(171, 171, 171);
}
.elreview .sidebar .element {
  margin: 0px 0px 30px 0px;
}

.elreview .month {
  background: #a8153b;
  color: #fff;
  width: 50px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 0.9rem;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 2px;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 2px solid rgb(67, 67, 67);
}

.sidebar-elreview {
  min-width: 280px;
}

@media (min-width: 992px) {
  .sidebar-elreview {
    margin-right: 28px;
  }
}

@media (max-width: 992px) {
  .sidebar-elreview {
    margin-right: 0px;
  }
}

/* ---------------------------------------------- */

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: streach;
  align-items: center;
  justify-content: center;
}
.text-box {
  width: 50%;
  display: block;
  padding-right: 20px;
}
.image-box {
  width: 50%;
  display: block;
  margin-bottom: 0px;
}

.text-box2 {
  width: 70%;
  display: block;
  padding-right: 20px;
}
.image-box2 {
  width: 30%;
  display: block;
  padding-right: 20px;
  margin-bottom: 0px;
}
.scard {
  text-align: center;
  margin: 10px 10px 30px 10px;
  padding: 20px 20px 20px 20px;
  width: 340px;
  height: 280px;
  border: 1px solid rgb(171, 171, 171);
  background: linear-gradient(
    to bottom,
    rgba(19, 0, 0, 0.8),
    rgba(73, 50, 0, 0.8) 76px,
    #fff 76px 204px
  );
}

@media (min-width: 992px) {
  .grid {
    flex-direction: row;
  }
  .text-box {
    width: 70%;
  }
  .image-box {
    width: 30%;
    margin-bottom: 0px;
  }
  .text-box2 {
    width: 75%;
    display: block;
    padding-right: 20px;
  }
  .image-box2 {
    width: 25%;
    display: block;
    padding-right: 20px;
  }
  .scard {
    width: 340px;
  }
}

@media (max-width: 992px) {
  .grid {
    flex-direction: column;
  }
  .text-box {
    width: 100%;
  }
  .image-box {
    width: 100%;
    margin-bottom: 40px;
  }
  .text-box2 {
    width: 100%;
  }
  .image-box2 {
    width: 100%;
    margin-bottom: 20px;
  }
  .scard {
    width: 100%;
  }
}

/* ------------------------------------- */

.circle-icon {
  background: #fff;
  padding: 20px;
  border-radius: 50%;
  border: 1px solid rgb(171, 171, 171);
}

.callout-box {
  margin: 20px 0px 20px 0px;
  background: linear-gradient(145deg, rgb(73, 50, 0), rgb(19, 0, 0));
  color: #fff;
  width: 100%;
  padding: 50px;
}
.callout-box .title {
  font-size: 2rem;
  text-align: center;
}

.text-highlight {
  color: #ffae00;
}

.element {
  position: relative;
  margin: 10px 10px 30px 10px;
  width: 340px;
  border: 1px solid rgb(171, 171, 171);
}

.youtube-button {
  width: 200px !important;
}

.tag-box {
  margin: 10px;
  display: inline;
}

.arrow-left {
  width: 10;
  height: 10;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid blue;
  display: inline;
}

.triangle-left {
  width: 20px;
  height: 20px;
  margin: 10px;
  padding: 2px 16px 6px 24px;
  line-height: 20px;
  text-align: center;
  background: rgb(209, 251, 209);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  display: inline;
}

.tags {
  display: flex;
  justify-content: left;
}

.tag {
  font-size: 14px;
  background: rgb(247, 229, 171);
  color: #000;
  padding: 0px 4px 0px 4px;
  margin: 0px 0px 4px 4px;
}

.hideme {
  display: none;
}

.form-control {
  border: 2px solid #ccc;
  border-radius: 2;
}

.btn {
  border: 2px solid #ccc;
  border-radius: 2;
}

.btn-success {
  color: #fff;
  font-weight: bold;
  background-color: #198754;
}

.center-content {
  text-align: center;
  padding: 10px;
}

.bullets {
  h2 {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
  }
  ul {
    list-style-type: disc;
    font-weight: bold;
  }
}
